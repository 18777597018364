<template>
    <v-app>
    </v-app>
</template>
<script>
import { YhtDuct } from '@/libs/yht.js'

export default {
    data: () => ({
        duct: new YhtDuct()
    }),
    methods: {
        binArrayToJson(binArray) {
            var str = "";
            for (var i = 0; i < binArray.length; i++) {
                str += String.fromCharCode(parseInt(binArray[i]));
            } 
            return JSON.parse(str)
        }
    },
    created() {
        console.log('index created');
        this.duct.open('/ducts/wsd')
        this.duct.invokeOnOpen(async () => {
            try {
                const year = 2022;
                const week = 40;
                await this.duct.call(this.duct.EVENT.CHART_TEST, { 
                    year: year, 
                    week: week, 
                    data_type: 'stocks'
                });
            } catch (e) {
                console.log(e);
            }
        });
    },
}
</script>
